<template>
    <b-alert v-model="hasErrors" variant="danger">
        <ul>
            <li v-for="(item, index) in this.errors" :key='index'>
                <ul>
                    <li v-for="e in item" :key='e'>
                    {{ e }}
                    </li>
                </ul>
            </li>
        </ul>
    </b-alert>
</template>

<script>
export default {
    props: ['hasErrors', 'errors']
}
</script>